import React, { useEffect } from "react";
import { Flex, Box, Heading } from "rebass";
import CompanyTitle from "./CompanyTitle";
import { companies, Company } from './CompaniesData';

interface IProps {
  listProjects?: string | undefined;
}

const CompanyTitleListing = (props: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section>
      <Flex
        flexWrap="wrap"
        justifyContent="space-between"
        variant="container"
      >
        <Box width={[1 / 1]} mb={[0,3]}>
          <Heading as="h3" variant="catHeading">
            Previously at 
          </Heading>
        </Box>
        
        {/* Project card list */}
        {companies.map((company:Company, index) => (
          <React.Fragment key={company.id}>
            <CompanyTitle
              page={company.page}
              title={company.title}
              location={company.location}
              tagline={company.tagline}
              discipline={company.discipline}
              year={company.year}
              label={company.label}
              id={company.id}
              index={index}
            />
            {props.listProjects ? (
              <> 
              {company.companyProjects && (
                <company.companyProjects />
              )}
              </>
            ) : null}
          </React.Fragment>
        ))}
      </Flex>
    </section>
  );
};

export default CompanyTitleListing;
