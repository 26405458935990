import React, { SVGAttributes } from 'react';

type IProps = SVGAttributes<SVGElement>;

const Arrow = (props: IProps) => <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Arrow</title>
    <g id="icon-arrow" fill="currentColor">
    <path d="M21.1261175,11.40538 L13.3605575,4.22589995 L11.7732575,6.15507996 C14.3617775,8.42613997 17.4875375,10.15996 19.5632375,11.33212 L19.5143975,11.47864 C16.8037775,11.1856 12.6523775,10.86814 9.23357749,10.86814 L4.54493747,10.86814 L4.54493747,13.0171 L9.23357749,13.0171 C12.6523775,13.0171 16.8037775,12.74848 19.5143975,12.47986 L19.5632375,12.62638 C17.4631175,13.77412 14.3617775,15.4591 11.7732575,17.73016 L13.3605575,19.65934 L21.1261175,12.47986 L21.1261175,11.40538 Z" id="→"></path>
    </g>
</svg>;

export default Arrow;