import React, { useEffect, useRef } from "react";
import { Flex, Box, Heading, Text } from "rebass";
import { ULink } from "../components/ULink";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface IProps {
  title?: string | undefined;
  location?: string | undefined;
  to?: string | undefined;
  page?: string | undefined;
  url?: string | undefined;
  discipline?: string | undefined;
  year?: string | undefined;
  id?: string | undefined;
  index?: number | undefined;
  label?: string | undefined;
  tagline?: string | undefined;
  companyProjects?: React.FC | null;
}
const CompanyTitle = (props: IProps) => {
  const revealTitleRefs = useRef([null]);
  revealTitleRefs.current = [];
  useEffect(() => {
    revealTitleRefs.current.forEach((el) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.2,
          autoAlpha: 1,
          ease: "Power4.easeOut",
          scrollTrigger: {
            trigger: el,
            toggleActions: "play none none reverse",
            scrub: 0.5,
          },
        }
      );
    });
  }, []);

  const addTitleToRefs = (el: any) => {
    if (el && !revealTitleRefs.current.includes(el)) {
      revealTitleRefs.current.push(el);
    }
  };
  return (
    <Box variant="containerRelatedProject" ref={addTitleToRefs}>
      <ULink
        {...props}
        to={props.page!}
        activeClassName="active"
        partiallyActive={true}
        sx={{
          variant: "links.imgLink",
          display: "block",
          cursor: "pointer",
        }}
      >
        <Flex flexWrap={["wrap", "nowrap"]} py={[3]}>
          {/* Title */}
          <Box order={[0, 0, 0]} flex={["auto 0 0", "auto 0 0", "25ch 0 0"]}>
            <Heading
              as="h2"
              variant="paragraphXL"
              fontWeight="heading"
              mr={[4]}
              mb={[2]}
            >
              {props.title}
            </Heading>
          </Box>

          {/* Label + desc */}
          <Box
            order={[2, 0, 0]}
            flex={["100% 0 0", "1 0 0"]}
            alignItems="center"
            justifyItems="stretch"
          >
            {props.label ? (
              <Text as="span" variant="projectLabel" ml={[0]} mb={[2]}>
                {props.label}
              </Text>
            ) : null}
            {props.tagline ? (
              <Text as="p" mb={[2]}>
                {props.tagline}
              </Text>
            ) : null}

            {props.location || props.discipline ? (
              <Box>
                {props.discipline && (
                  <Text
                    as="span"
                    variant="caption"
                    sx={{ display: "inline-block" }}
                    py={[0, 0, 0]}
                  >
                    {props.discipline}
                  </Text>
                )}
                {props.location && (
                  <Text
                    as="span"
                    variant="caption"
                    sx={{ display: "inline-block" }}
                    py={[0, 0, 0]}
                  >
                    &nbsp; | &nbsp;{props.location}
                  </Text>
                )}
              </Box>
            ) : null}
          </Box>

          {/* Year */}
          <Box order={[1, 0, 0]} flex={["1 0 0"]} alignItems="flex-end">
            {props.year ? (
              <Text as="p" textAlign="right" fontWeight="heading">
                {props.year}
              </Text>
            ) : null}
          </Box>
        </Flex>
      </ULink>
    </Box>
  );
};

export default CompanyTitle;
