import React from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Arrow from "../icons/Arrow";

const BackToTop = () => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const pageUrl = pathname + "#gatsby-focus-wrapper";
  return (  
      <Flex
        flexWrap="wrap"
        alignItems="center"
        variant="container"
      >
        <Box width={[1/1]}>
          <Text textAlign="center">
            <StyledAnchorLink to={pageUrl} title="Back to top">
              <span>Top</span>
              <Arrow />
            </StyledAnchorLink>
          </Text>
        </Box>
      </Flex>
  );
};

const StyledAnchorLink = styled(AnchorLink)`
  
  & > span {
    display: block;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    padding: 0.4rem;
  }
  ,
  &:hover span {
    opacity: 1;
  }
  ,
  & > svg {
    width: 3.2rem;
    height: auto;
    transform: rotate(-90deg);
  }
`;

export default BackToTop;
