/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { useEffect } from "react";
import { Flex, Box } from "rebass";
import { ULink } from "../../components/ULink";
import BackToTop from "../../components/BackToTop";
import BoseProjectCards from "../../components/BoseProjectCards";
import CompanyTitleListing from "../../components/CompanyTitleListing";
import CompanyDetail from "../../components/CompanyDetail";
import Arrow from "../../icons/Arrow";
import Layout from "../../layouts/";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

function CompanyBoseProjects() {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.backgroundElevation as string;
  const projectTextColor = theme?.colors?.text as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);
  return (
    <Layout>
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Flex flexWrap="wrap" variant="containerPage">
          <ULink
            to="/work/"
            activeClassName="active"
            sx={{
              variant: "buttons.outline",
              fontSize: [1, 1, 2],
              my: [2],
              mr: [1, 2, 2],
              position: "relative",
              zIndex: "1",
            }}
          >
            <Arrow /> All Projects
          </ULink>

          <Box width={[1 / 1]}>
            <Flex flexWrap={["wrap", "nowrap"]}>
              <Box flex={["1 0 0"]}>
                <CompanyDetail title="BOSE" />
              </Box>
            </Flex>
          </Box>

          <BoseProjectCards />
        </Flex>

        <CompanyTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}

export default CompanyBoseProjects;
