import React from "react";
import { Flex, Box, Heading, Text } from "rebass";
import { companies, Company } from "./CompaniesData";
const CompanyDetail: React.FC<{ title: string }> = ({ title }) => {
  // Filter the companies array to find the company with the matching title
  const company = companies.find((company: Company) => company.title === title);

  // If no company is found, render a message
  if (!company) {
    return <div>Company not found</div>;
  }

  // Render the company details
  return (
    <>
      <Flex>
        {/* Name */}
        <Box flex={["1 0 0"]}>
          <Heading as="h1" variant="bigTitle" className="uppercase" mb={[4]}>
            {company.title}
          </Heading>
        </Box>

        {/* Year */}
        <Box flex={["1 0 0"]} alignItems="flex-end" alignSelf="center">
          <Text
            as="p"
            variant="paragraphXL"
            textAlign="right"
            fontWeight="medium"
          >
            {company.year}
          </Text>
        </Box>
      </Flex>
      <Flex ml={["0", "0", "0","0","25ch"]}>
        
        {/* Label + desc */}
        <Box flex={["1 0 0"]} alignItems="center" justifyItems="stretch">
          {company.label ? (
            <Text as="p" variant="projectLabel">
              {company.label}
            </Text>
          ) : null}
          
          {company.tagline ? (
            <Text as="p" mb={[2]}>{company.tagline}</Text>
          ) : null}
           {company.location || company.discipline ? (
             <Box>
               {company.discipline && (
                 <Text
                   as="span"
                   variant="caption"
                   sx={{ display: "inline-block" }}
                   py={[0, 0, 0]}
                 >
                   {company.discipline}
                 </Text>
               )}
               {company.location && (
                 <Text
                   as="span"
                   variant="caption"
                   sx={{ display: "inline-block" }}
                   py={[0, 0, 0]}
                 >
                   &nbsp; | &nbsp;{company.location}
                 </Text>
               )}
             </Box>
           ) : null}
        </Box>

        {/* {company.companyProjects && (
        <div>
          <company.companyProjects />
        </div>
      )} */}
      </Flex>
    </>
  );
};

export default CompanyDetail;
